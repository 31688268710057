import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Vue from 'vue'
import router from '../../../router'

export default function useMaterialRequestsList() {
  const toast = useToast()
  const refPurchaseOrderListTable = ref(null)
  const refPurchaseOrderListTableFinished = ref(null)
  const refPurchaseOrderListTableHO = ref(null)
  const refPurchaseOrderListTableFinishedHO = ref(null)
  const refPurchaseOrderListTableVoid = ref(null)
  const tableColumns = [
    { key: 'Number', sortable: true },
    { key: 'PurchaseRequestNumber', sortable: true },
    { key: 'MaterialRequestNumbers', sortable: true },
    { key: 'DateCreated', sortable: true },
  ]
  const tableColumnsVoid = [
    { key: 'Number', sortable: true },
    { key: 'PurchaseRequestNumber', sortable: true },
    { key: 'Supplier', sortable: true },
    { key: 'withPPN', sortable: false },
    { key: 'ShipmentBy', sortable: true },
    { key: 'DeliverySchedule', sortable: true },
    { key: 'BillingAddress', sortable: true },
    { key: 'DateCreated', sortable: true },
    { key: 'VoidBy', sortable: false },
    { key: 'VoidTime', sortable: false },
    { key: 'Action', sortable: true },
  ]
  const perPage = ref(10)
  const perPageHO = ref(10)
  const perPageFinished = ref(10)
  const perPageFinishedHO = ref(10)
  const perPageVoid = ref(10)
  const totalPurchaseOrder = ref(0)
  const totalPurchaseOrderHO = ref(0)
  const totalPurchaseOrderFinished = ref(0)
  const totalPurchaseOrderFinishedHO = ref(0)
  const totalPurchaseOrderVoid = ref(0)
  const currentPage = ref(1)
  const currentPageHO = ref(1)
  const currentPageFinished = ref(1)
  const currentPageFinishedHO = ref(1)
  const currentPageVoid = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const searchQueryHO = ref('')
  const searchQueryFinished = ref('')
  const searchQueryFinishedHO = ref('')
  const searchQueryVoid = ref('')
  const sortBy = ref('id')
  const sortByHO = ref('id')
  const sortByFinished = ref('id')
  const sortByFinishedHO = ref('id')
  const sortByVoid = ref('id')
  const isSortDirDesc = ref(true)
  const isSortDirDescHO = ref(true)
  const isSortDirDescFinished = ref(true)
  const isSortDirDescFinishedHO = ref(true)
  const isSortDirDescVoid = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const startDateFilter = ref('')
  const endDateFilter = ref('')
  const startDateFilterHO = ref('')
  const endDateFilterHO = ref('')
  const startDateFilterFinished = ref('')
  const endDateFilterFinished = ref('')
  const startDateFilterFinishedHO = ref('')
  const endDateFilterFinishedHO = ref('')
  const startDateFilterVoid = ref('')
  const endDateFilterVoid = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refPurchaseOrderListTable.value ? refPurchaseOrderListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPurchaseOrder.value,
    }
  })

  const dataMetaHO = computed(() => {
    const localItemsCount = refPurchaseOrderListTableHO.value ? refPurchaseOrderListTableHO.value.localItems.length : 0
    return {
      from: perPageHO.value * (currentPageHO.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageHO.value * (currentPageHO.value - 1) + localItemsCount,
      of: totalPurchaseOrderHO.value,
    }
  })

  const dataMetaFinished = computed(() => {
    const localItemsCount = refPurchaseOrderListTableFinished.value ? refPurchaseOrderListTableFinished.value.localItems.length : 0
    return {
      from: perPageFinished.value * (currentPageFinished.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageFinished.value * (currentPageFinished.value - 1) + localItemsCount,
      of: totalPurchaseOrderFinished.value,
    }
  })
  const dataMetaFinishedHO = computed(() => {
    const localItemsCount = refPurchaseOrderListTableFinishedHO.value ? refPurchaseOrderListTableFinishedHO.value.localItems.length : 0
    return {
      from: perPageFinishedHO.value * (currentPageFinishedHO.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageFinishedHO.value * (currentPageFinishedHO.value - 1) + localItemsCount,
      of: totalPurchaseOrderFinishedHO.value,
    }
  })

  const dataMetaVoid = computed(() => {
    const localItemsCount = refPurchaseOrderListTableVoid.value ? refPurchaseOrderListTableVoid.value.localItems.length : 0
    return {
      from: perPageVoid.value * (currentPageVoid.value - 1) + (localItemsCount ? 1 : 0),
      to: perPageVoid.value * (currentPageVoid.value - 1) + localItemsCount,
      of: totalPurchaseOrderVoid.value,
    }
  })

  const refetchData = () => {
    refPurchaseOrderListTable.value.refresh()
  }

  const refetchDataFinished = () => {
    refPurchaseOrderListTableFinished.value.refresh()
  }
  const refetchDataVoid = () => {
    refPurchaseOrderListTableVoid.value.refresh()
  }

  watch([currentPage, currentPageHO, currentPageFinished, currentPageFinishedHO, currentPageVoid, perPage, perPageHO, perPageFinished, perPageFinishedHO, perPageVoid, searchQuery, searchQueryHO, searchQueryFinished, searchQueryFinishedHO, searchQueryVoid, roleFilter, statusFilter, sortBy, sortByHO, sortByFinished, sortByFinishedHO, sortByVoid, isSortDirDesc, isSortDirDescHO, isSortDirDescFinished, isSortDirDescFinishedHO, isSortDirDescVoid, startDateFilter, startDateFilterHO, startDateFilterFinished, startDateFilterFinishedHO, startDateFilterVoid, endDateFilter, endDateFilterHO, endDateFilterFinished, endDateFilterFinishedHO, endDateFilterVoid], () => {
    refetchData()
  })

  const fetchPurchaseOrders = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPage.value * (currentPage.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_MANAGEMENT}?limit=${perPage.value}&offset=${to}&status=ongoing&date_start=${startDateFilter.value}&date_end=${endDateFilter.value}&search=${searchQuery.value}`, { headers })
      .then(response => {
        totalPurchaseOrder.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const fetchPurchaseOrdersHO = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageHO.value * (currentPageHO.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER}?limit=${perPageHO.value}&offset=${to}&status=ongoing&date_start=${startDateFilter.value}&date_end=${endDateFilter.value}&search=${searchQuery.value}&is_center=true`, { headers })
      .then(response => {
        totalPurchaseOrderHO.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchPurchaseOrdersFinished = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageFinished.value * (currentPageFinished.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER}?limit=${perPageFinished.value}&offset=${to}&status=finished&date_start=${startDateFilterFinished.value}&date_end=${endDateFilterFinished.value}&search=${searchQueryFinished.value}`, { headers })
      .then(response => {
        totalPurchaseOrderFinished.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchPurchaseOrdersFinishedHO = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageFinishedHO.value * (currentPageFinishedHO.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER}?limit=${perPageFinished.value}&offset=${to}&status=finished&date_start=${startDateFilterFinished.value}&date_end=${endDateFilterFinished.value}&search=${searchQueryFinished.value}&is_center=true`, { headers })
      .then(response => {
        totalPurchaseOrderFinished.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchPurchaseOrdersVoid = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPageVoid.value * (currentPageVoid.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER}?limit=${perPageVoid.value}&offset=${to}&status=void&date_start=${startDateFilterVoid.value}&date_end=${endDateFilterVoid.value}&search=${searchQueryVoid.value}`, { headers })
      .then(response => {
        totalPurchaseOrderVoid.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchRoles = () => {
    const userToken = Vue.$cookies.get('userToken')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ROLES}`, { headers })
      .then(response => {
        console.log(response)
        const temp = [
          { label: 'Admin', value: 'admin' },
          { label: 'Author', value: 'author' },
          { label: 'Editor', value: 'editor' },
          { label: 'Maintainer', value: 'maintainer' },
          { label: 'Subscriber', value: 'subscriber' },
        ]
        return temp
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching role list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchPurchaseOrders,
    fetchPurchaseOrdersHO,
    fetchPurchaseOrdersFinished,
    fetchPurchaseOrdersFinishedHO,
    fetchPurchaseOrdersVoid,
    fetchRoles,
    tableColumns,
    tableColumnsVoid,
    perPage,
    perPageFinished,
    perPageVoid,
    currentPage,
    currentPageFinished,
    currentPageVoid,
    totalPurchaseOrder,
    totalPurchaseOrderFinished,
    totalPurchaseOrderVoid,
    dataMeta,
    dataMetaFinished,
    dataMetaVoid,
    perPageOptions,
    searchQuery,
    searchQueryFinished,
    searchQueryVoid,
    sortBy,
    sortByFinished,
    sortByVoid,
    isSortDirDesc,
    isSortDirDescFinished,
    isSortDirDescVoid,
    refPurchaseOrderListTable,
    refPurchaseOrderListTableFinished,
    refPurchaseOrderListTableVoid,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    refetchDataFinished,
    refetchDataVoid,
    // Extra Filters
    roleFilter,
    statusFilter,
    startDateFilter,
    endDateFilter,
    startDateFilterFinished,
    endDateFilterFinished,
    startDateFilterVoid,
    endDateFilterVoid,
    perPageHO,
    perPageFinishedHO,
    currentPageHO,
    currentPageFinishedHO,
    totalPurchaseOrderHO,
    totalPurchaseOrderFinishedHO,
    dataMetaHO,
    dataMetaFinishedHO,
    searchQueryHO,
    searchQueryFinishedHO,
    sortByHO,
    sortByFinishedHO,
    isSortDirDescHO,
    isSortDirDescFinishedHO,
    refPurchaseOrderListTableHO,
    refPurchaseOrderListTableFinishedHO,
  }
}
